import { CircularProgress, Stack } from '@mui/material'
import { useQuery } from '@tanstack/react-query'

import Decimal from 'decimal.js'
import React, { createContext, FC, useEffect, useMemo, useState } from 'react'
import { useLocation } from 'react-router-dom'

import { useUserLocation } from '../../hooks'
import { Difficulties, Status, StatusEnum, TrailSystem } from '../../models'
import { ApiQueryService } from '../../services'
import { convertArrayToEnum, safeJsonParse, useUrlStateParams } from '../../utils'
import { trailSystemFilter, TrailSystemFilterParams } from './trailSystemFilter'

// Generates a number between 0 and 2 locked to the current hour for consistent results why demoing
function getRandomDemoStatusIndex(seed?: number) {
  const currentHour = new Date().getHours()
  const hourSeed = currentHour + (seed || 0)
  const x = Math.sin(hourSeed) * 10000
  const randomValue = x - Math.floor(x)
  return Math.floor(randomValue * 3)
}

export const TrailSystemContext = createContext<{
  filterParamsCount: number
  query?: ReturnType<typeof useQuery<TrailSystem[]>>
  filterParams: TrailSystemFilterParams
  setFilterParams: React.Dispatch<React.SetStateAction<TrailSystemFilterParams>>
  trailSystems: TrailSystem[]
  userCoords?: { lat: number; long: number }
  userLocality?: string
  userLocApproved: boolean
}>({
  filterParams: {},
  filterParamsCount: 0,
  setFilterParams: (_) => {
    throw new Error('TrailSystemContext must be used within a TrailSystemProvider')
  },
  trailSystems: [],
  userLocApproved: true,
})

export const TrailSystemProvider: FC<{ children: React.ReactNode }> = (props) => {
  const [term, setTerm] = useUrlStateParams<TrailSystemFilterParams['term']>(
    '',
    'term',
    (value) => value || '',
    (value) => value,
  )
  const [difficultyIds, setDifficultyIds] = useUrlStateParams<TrailSystemFilterParams['difficultyIds']>(
    [],
    'difficulties',
    (value) => (value?.length ? JSON.stringify(value) : ''),
    (value) =>
      convertArrayToEnum<Difficulties>(
        Difficulties,
        safeJsonParse<string[]>(value, []).filter((v) => !isNaN(parseInt(v, 10))),
      ),
  )
  const [featureIds, setFeatureIds] = useUrlStateParams<TrailSystemFilterParams['featureIds']>(
    [],
    'features',
    (value) => (value?.length ? JSON.stringify(value) : ''),
    (value) => safeJsonParse<string[]>(value, []).map((v) => parseInt(v, 10)),
  )
  const [statuses, setStatuses] = useUrlStateParams<TrailSystemFilterParams['statuses']>(
    [],
    'statuses',
    (value) => (value?.length ? JSON.stringify(value) : ''),
    (value) =>
      convertArrayToEnum<Status>(
        StatusEnum,
        safeJsonParse<string[]>(value, [])
          .map((v) => parseInt(v, 10))
          .filter((v) => !isNaN(v)),
      ),
  )
  const [surfaceTypes, setSurfaceTypes] = useUrlStateParams<TrailSystemFilterParams['surfaceTypes']>(
    [],
    'surfaceTypes',
    (value) => (value?.length ? JSON.stringify(value) : ''),
    (value) => safeJsonParse<string[]>(value, []),
  )
  const [radius, setRadius] = useUrlStateParams<TrailSystemFilterParams['radius']>(
    0,
    'radius',
    (value) => `${value || 0}`.replace(/[^0-9.]/g, ''),
    (value) => parseFloat(`${value}`.replace(/[^0-9.]/g, '')),
  )
  const [trailSystems, setTrailSystems] = useState<TrailSystem[]>([])
  const location = useLocation()

  const filterParams: TrailSystemFilterParams = useMemo(
    () => ({
      difficultyIds,
      featureIds,
      radius,
      statuses,
      surfaceTypes,
      term,
    }),
    [term, difficultyIds, featureIds, statuses, surfaceTypes, radius],
  )

  const setFilterParams = (
    values: TrailSystemFilterParams | ((prev: TrailSystemFilterParams) => TrailSystemFilterParams),
  ) => {
    const newValues = values instanceof Function ? values(filterParams) : values
    setTerm(newValues.term || '')
    setDifficultyIds(newValues.difficultyIds || [])
    setFeatureIds(newValues.featureIds || [])
    setStatuses(newValues.statuses || [])
    setSurfaceTypes(newValues.surfaceTypes || [])
    setRadius(newValues.radius || 0)
  }

  const userLocation = useUserLocation()

  useEffect(() => {
    if (userLocation.userLocApproved && !userLocation.userCoords) {
      // Call this on initial page load so we can determine if they've already denied us access to their coords
      userLocation.requestUserCoords()
    }
  }, [])

  const trailSystemQuery = useQuery<TrailSystem[]>({
    queryFn: async () =>
      new ApiQueryService().get<TrailSystem[]>({
        endpoint: 'trail-system/list',
      }),
    queryKey: ['trail-system/list'],
    refetchInterval: () => {
      // refetch every hour at the 21st minute
      const minuteToRefetch = 21
      const thisMinute = new Date().getMinutes()
      return (
        (thisMinute >= minuteToRefetch ? 60 - thisMinute + minuteToRefetch : minuteToRefetch - thisMinute) * 60 * 1000
      )
    },
    // fake freeze thaw status for testing
    select: (data) => {
      if (location.hash.includes('demo=true')) {
        data = data.map((trailSystem) => {
          trailSystem.trails = trailSystem.trails?.map((trail) => {
            if (trail.status) {
              trail.status.status = [StatusEnum.Firm, StatusEnum.Fair, StatusEnum.Rutty][
                getRandomDemoStatusIndex(trail.id)
              ]
            }
            return trail
          })
          return trailSystem
        })
      }
      //   if (data?.[1]?.trails?.[0]?.status !== undefined) {
      //     data[1].trails[0].status.freezeThawStatus = {
      //       date: '2021-10-01T00:00:00Z',
      //       freezeCounter: 1,
      //       status: 1,
      //       thawCounter: 1,
      //     }
      //   }
      return data
    },
    staleTime: 1000 * 60 * 61, // 1 hour + 1 minute in case app was inactive past the refetch interval
  })

  const onFilterChange = (
    currentTrailSystems: TrailSystem[] | undefined,
    newSearchParams: TrailSystemFilterParams,
    newUserCoords?: { lat: number; long: number },
  ) => {
    const filteredTrailSystems = !Array.isArray(currentTrailSystems)
      ? []
      : trailSystemFilter(currentTrailSystems, newSearchParams, newUserCoords)
    setTrailSystems(filteredTrailSystems)
  }

  useEffect(() => {
    if (filterParams.radius && !userLocation.userCoords && userLocation.userLocApproved) {
      userLocation
        .requestUserCoords()
        .then((coords) => {
          onFilterChange(trailSystemQuery.data, filterParams, coords)
        })
        .catch(() => {
          // If user denies access to geolocation, quietly continue and don't allow radius searches.
          setFilterParams((prev) => ({ ...prev, radius: undefined }))
          onFilterChange(trailSystemQuery.data, { ...filterParams, radius: undefined })
        })
    } else {
      onFilterChange(trailSystemQuery.data, filterParams, userLocation.userCoords)
    }
  }, [filterParams, trailSystemQuery.data, userLocation.userCoords, userLocation.userLocApproved])

  const ctx = useMemo(
    () => ({
      filterParams,
      filterParamsCount: new Decimal(filterParams.term?.length ? 1 : 0)
        .add(filterParams.difficultyIds?.length || 0)
        .add(filterParams.featureIds?.length || 0)
        .add(filterParams.statuses?.length || 0)
        .add(filterParams.surfaceTypes?.length || 0)
        .add(filterParams.radius ? 1 : 0)
        .toNumber(),
      query: trailSystemQuery,
      setFilterParams,
      trailSystems,
      userCoords: userLocation.userCoords,
      userLocApproved: userLocation.userLocApproved,
      userLocality: userLocation.userLocality,
    }),
    [
      setFilterParams,
      filterParams,
      trailSystems,
      trailSystemQuery,
      userLocation.userCoords,
      userLocation.userLocApproved,
      userLocation.userLocality,
    ],
  )

  return (
    <TrailSystemContext.Provider value={ctx}>
      {
        // preload the trail system list for initial app loading indicator
        // trigger suspense the first time the list is loading
        !trailSystemQuery?.isSuccess ? (
          <Stack alignItems="center" height="100vh" justifyContent="center" width="100vw">
            <CircularProgress size={100} thickness={3} />
          </Stack>
        ) : (
          props.children
        )
      }
    </TrailSystemContext.Provider>
  )
}
